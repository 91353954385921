<template>
  <div>
    <!-- Start Header Area -->
    <header class="header-section d-none d-xl-block">
      <div class="header-wrapper">
        <div
          id="header"
          class="header-bottom header-bottom-color--golden section-fluid sticky-header sticky-color--golden"
        >
          <div class="container" style="max-width: 95%">
            <div class="row">
              <div
                class="col-12 d-flex align-items-center justify-content-between"
              >
                <!-- Start Header Logo -->
                <div class="header-logo">
                  <div class="logo">
                    <router-link to="/"
                      ><img
                        :src="require('@/assets/ticas-logo.png')"
                        alt="logo"
                        style="max-width: 80%; margin: 20px 0"
                    /></router-link>
                  </div>
                </div>
                <!-- End Header Logo -->

                <!-- Start Header Main Menu -->
                <div
                  class="main-menu menu-color--black menu-hover-color--golden"
                >
                  <nav>
                    <ul>
                      <li>
                        <router-link to="/">Home</router-link>
                        <!-- Sub Menu -->
                      </li>

                      <li class="has-dropdown has-megaitem">
                        <a href="#">Shop <i class="fa fa-angle-down"></i></a>
                        <!-- Mega Menu -->
                        <div
                          class="mega-menu"
                          style="width: 60%; min-width: 60%"
                        >
                          <ul class="mega-menu-inner">
                            <!-- Mega Menu Sub Link -->
                            <li
                              class="mega-menu-item"
                              v-for="(category, index) in categories"
                              :key="index"
                            >
                              <a href="#" class="mega-menu-item-title">{{
                                category.name
                              }}</a>
                              <ul
                                class="mega-menu-sub"
                                v-if="category.children.length > 0"
                              >
                                <li
                                  v-for="(
                                    childCategory, idx
                                  ) in category.children"
                                  :key="idx"
                                >
                                  <router-link
                                    :to="
                                      '/shop?category_id=' + childCategory.id
                                    "
                                    >{{ childCategory.name }}</router-link
                                  >
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li>
                        <router-link to="/partnerships"
                          >Partnerships & Collaborations</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/about-us">About Us</router-link>
                      </li>
                      <li>
                        <router-link to="/contact-us">Contact Us</router-link>
                      </li>
                      <li v-if="!authenticated">
                        <router-link to="/login">Login | Register</router-link>
                      </li>
                      <li v-else class="has-dropdown">
                        <a href="#">Account <i class="fa fa-angle-down"></i></a>
                        <!-- Sub Menu -->
                        <ul class="sub-menu">
                          <li>
                            <router-link to="/profile">Dashboard</router-link>
                          </li>
                          <li>
                            <router-link to="/logout">logout</router-link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
                <!-- End Header Main Menu Start -->

                <!-- Start Header Action Link -->
                <ul
                  class="header-action-link action-color--black action-hover-color--golden"
                >
                  <li>
                    <a v-b-toggle.offcanvas-wishlish class="offcanvas-toggle">
                      <i class="far fa-heart"></i>
                      <span class="item-count">{{ wishlist.length }}</span>
                    </a>
                  </li>
                  <li>
                    <a v-b-toggle.offcanvas-add-cart class="offcanvas-toggle">
                      <i class="fas fa-shopping-bag"></i>
                      <span class="item-count">{{ cart.length }}</span>
                    </a>
                  </li>
                  <!-- <li>
                    <a
                      v-b-toggle.search_sidebar
                      class="search_width offcanvas-toggle"
                    >
                      <img src="../assets/img/svg/search.svg" alt="img" />
                    </a>
                  </li> -->
                  <li>
                    <a
                      v-b-toggle.offcanvas-about
                      class="offacnvas offside-about offcanvas-toggle"
                      ><i class="fas fa-bars"></i
                    ></a>
                  </li>
                </ul>
                <!-- End Header Action Link -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Start Header Area -->

    <!-- Start Mobile Header -->
    <div
      id="mobile_header"
      class="mobile-header sticky-header sticky-color--golden mobile-header-bg-color--golden section-fluid d-lg-block d-xl-none"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <!-- Start Mobile Left Side -->
            <div class="mobile-header-left">
              <ul class="mobile-menu-logo">
                <li>
                  <router-link to="/">
                    <div class="logo">
                      <img
                        :src="require('@/assets/ticas-logo.png')"
                        alt="logo"
                      />
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <!-- End Mobile Left Side -->

            <!-- Start Mobile Right Side -->
            <div class="mobile-right-side">
              <ul
                class="header-action-link action-color--black action-hover-color--golden"
              >
                <li>
                  <a v-b-toggle.offcanvas-wishlish class="offcanvas-toggle">
                    <i class="far fa-heart"></i>
                    <span class="item-count">{{ wishlist.length }}</span>
                  </a>
                </li>
                <li>
                  <a v-b-toggle.offcanvas-add-cart class="offcanvas-toggle">
                    <i class="fas fa-shopping-bag"></i>
                    <span class="item-count">{{ cart.length }}</span>
                  </a>
                </li>
                <li>
                  <a
                    v-b-toggle.offcanvas-about
                    class="offacnvas offside-about offcanvas-toggle"
                    ><i class="fas fa-bars"></i
                  ></a>
                </li>
              </ul>
            </div>
            <!-- End Mobile Right Side -->
          </div>
        </div>
      </div>
    </div>
    <!-- End Mobile Header -->

    <!-- ...:::: Start Offcanvas Mobile Menu Section:::... -->
    <b-sidebar
      id="offcanvas-about"
      class="offcanvas-mobile-about-section"
      backdrop
      right
    >
      <!-- Start Mobile Menu Navbar Wrap -->
      <div class="mobile-menu-navbar-wrap">
        <!-- Start Mobile Menu Nav -->
        <div class="offcanvas-menu">
          <sidebar-menu :menu="menu" />
        </div>
        <!-- End Mobile Menu Nav -->
      </div>
      <!-- End Mobile Menu Navbar Wrap -->

      <!-- Start Mobile contact Info -->
      <div class="mobile-contact-info">
        <address class="address">
          <img
            :src="require('@/assets/ticas-logo-white.png')"
            alt="logo"
            style="height: 160px; width: 300px"
          />
          <!-- <span>Address: Your address goes here.</span>
          <span>Call Us: 0123456789, 0123456789</span>
          <span>Email: demo@example.com</span> -->
        </address>

        <ul class="social-link">
          <li>
            <a href="#"><i class="fab fa-facebook-f"></i></a>
          </li>
          <li>
            <a href="#"><i class="fab fa-twitter"></i></a>
          </li>
          <li>
            <a href="#"><i class="fab fa-instagram"></i></a>
          </li>
        </ul>

        <ul class="user-link">
          <!-- <li><router-link to="/profile/wishlist">Wishlist</router-link></li>
          <li><router-link to="/cart/">Cart</router-link></li>
          <li>
            <router-link to="/profile/checkout">Checkout</router-link>
          </li> -->
        </ul>
      </div>
      <!-- End Mobile contact Info -->
    </b-sidebar>
    <!-- ...:::: End Offcanvas Mobile Menu Section:::... -->

    <!-- Start Offcanvas Addcart Section -->
    <b-sidebar
      id="offcanvas-add-cart"
      class="offcanvas-add-cart-section"
      backdrop
      right
    >
      <div class="offcanvas-add-cart-wrapper">
        <h4 class="offcanvas-title">Shopping Cart</h4>
        <div v-if="cart.length">
          <ul class="offcanvas-cart">
            <li
              class="offcanvas-cart-item-single"
              v-for="(item, index) in cart"
              :key="index"
            >
              <div class="offcanvas-cart-item-block">
                <router-link
                  :to="{ path: '/product/' + item.id }"
                  class="offcanvas-cart-item-image-link"
                >
                  <img
                    :src="getImageUrl(item.images[0].src)"
                    alt="img"
                    class="offcanvas-cart-image"
                  />
                </router-link>
                <div class="offcanvas-cart-item-content">
                  <router-link
                    :to="{ path: '/product/' + item.id }"
                    class="offcanvas-cart-item-link"
                    >{{ item.title }}</router-link
                  >
                  <div class="offcanvas-cart-item-details">
                    <span class="offcanvas-cart-item-details-quantity"
                      >{{ item.quantity }} x</span
                    >
                    <span
                      v-if="item.discount"
                      class="offcanvas-cart-item-details-price"
                      >${{ discountedPrice(item) }}</span
                    >
                    <span v-else class="offcanvas-cart-item-details-price"
                      >${{ item.price }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="offcanvas-cart-item-delete text-right">
                <button
                  @click="removeCartItem(item)"
                  class="offcanvas-cart-item-delete bg-transparent remove-btn"
                >
                  <i class="far fa-trash-alt"></i>
                </button>
              </div>
            </li>
          </ul>

          <div class="offcanvas-cart-total-price">
            <span class="offcanvas-cart-total-price-text">Subtotal:</span>
            <span class="offcanvas-cart-total-price-value"
              >${{ cartTotal }}</span
            >
          </div>

          <ul class="offcanvas-cart-action-button">
            <li>
              <router-link
                to="/cart"
                class="theme-btn-one btn-black-overlay btn_md"
                >View Cart</router-link
              >
            </li>
            <li>
              <router-link
                to="/profile/checkout-1"
                class="theme-btn-one btn-black-overlay btn_md"
                >Checkout</router-link
              >
            </li>
          </ul>
        </div>

        <ul v-else class="offcanvas-cart">
          <li>Your cart is empty!</li>
        </ul>
      </div>
    </b-sidebar>
    <!-- End  Offcanvas Addcart Section -->

    <!-- Start Offcanvas Wishlist Sidebar Section -->
    <b-sidebar
      id="offcanvas-wishlish"
      class="offcanvas-add-cart-section"
      backdrop
      right
    >
      <div class="offcanvas-wishlist-wrapper">
        <h4 class="offcanvas-title">Wishlist</h4>

        <div v-if="wishlist.length">
          <ul class="offcanvas-wishlist">
            <li
              class="offcanvas-wishlist-item-single"
              v-for="(item, index) in wishlist"
              :key="index"
            >
              <div class="offcanvas-wishlist-item-block">
                <router-link
                  :to="{ path: '/product/' + item.id }"
                  class="offcanvas-wishlist-item-image-link"
                >
                  <img
                    :src="getImageUrl(item.images[0].src)"
                    alt="img"
                    class="offcanvas-wishlist-image"
                  />
                </router-link>
                <div class="offcanvas-wishlist-item-content">
                  <router-link
                    :to="{ path: '/product/' + item.id }"
                    class="offcanvas-wishlist-item-link"
                    >{{ item.title }}</router-link
                  >
                  <div class="offcanvas-wishlist-item-details">
                    <span class="offcanvas-wishlist-item-details-quantity"
                      >{{ item.quantity }} x</span
                    >
                    <span
                      v-if="item.discount"
                      class="offcanvas-wishlist-item-details-price"
                      >${{ discountedPrice(item) }}</span
                    >
                    <span v-else class="offcanvas-wishlist-item-details-price"
                      >${{ item.price }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="offcanvas-wishlist-item-delete text-right">
                <button
                  @click="removeWishlistItem(item)"
                  class="offcanvas-wishlist-item-delete bg-transparent remove-btn"
                >
                  <i class="far fa-trash-alt"></i>
                </button>
              </div>
            </li>
          </ul>
          <ul class="offcanvas-wishlist-action-button">
            <li>
              <router-link
                to="/profile/wishlist"
                class="theme-btn-one btn-black-overlay btn_md"
                >View wishlist</router-link
              >
            </li>
          </ul>
        </div>

        <ul v-else class="offcanvas-wishlist">
          <li>No Item in your wishlist!</li>
        </ul>
      </div>
    </b-sidebar>
    <!-- End Offcanvas Wishlist Sidebar Section -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Auth from "../Auth";
export default {
  data() {
    return {
      // Mobile Menu Item Data
      menu: [
        {
          title: "Home",
          href: "/",
        },
        {
          href: "/shop",
          title: "Shop",
        },
        {
          href: "/partnership",
          title: "Partnerships & Collaborations",
        },
        {
          href: "/about-us",
          title: "About Us",
        },
        {
          href: "/contact-us",
          title: "Contact Us",
        },
      ],
      products: [],
      category: [],
      categories: [],
      cartproduct: {},
      searchString: "",
      authenticated: Auth.authenticated(),
    };
  },

  mounted: function () {
    // Menu Js
    this.$nextTick(function () {
      window.onscroll = function () {
        myFunction();
      };

      var header = document.getElementById("header");
      var mobile_header = document.getElementById("mobile_header");
      var sticky = header.offsetTop;

      function myFunction() {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
          mobile_header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
          mobile_header.classList.remove("sticky");
        }
      }
    });
    // Menu End

    // this.productsArray()
  },

  created() {
    this.getCategories();
  },

  computed: {
    ...mapState({
      productslist: (state) => state.products.productslist,
      searchItems: (state) => state.products.searchProduct,
    }),
    ...mapGetters({
      cart: "cart/cartItems",
      cartTotal: "cart/cartTotalAmount",
      wishlist: "products/wishlistItems",
    }),
  },

  methods: {
    // Image Url
    getImageUrl(path) {
      return require("../assets/img/product-image/" + path);
    },

    // Discount Price
    discountedPrice(product) {
      const price = product.price - (product.price * product.discount) / 100;
      return price;
    },

    // For Delete/Remove Product Item
    removeCartItem: function (product) {
      this.$store.dispatch("cart/removeCartItem", product);
    },
    // For Delete/Remove wishlist Item
    removeWishlistItem: function (product) {
      this.$store.dispatch("products/removeWishlistItem", product);
    },
    searchProduct() {
      this.$store.dispatch("products/searchProduct", this.searchString);
    },
    getCartItems() {
      this.$http.get("cart").then((res) => {
        console.log(res);
      });
    },
    getCategories() {
      this.$http.get("categories/parents").then((res) => {
        this.categories = res.data.data;
      });
    },
  },
};
</script>

<style>
/* Mobile Menu Multi Dropdown Items Start */
.v-sidebar-menu .vsm--toggle-btn {
  display: none;
}
.v-sidebar-menu {
  position: relative;
  background-color: transparent;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  background-color: var(--main-theme-color);
}
.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: 3px 0px 0px 0px var(--main-theme-color) inset;
  box-shadow: 3px 0px 0px 0px var(--main-theme-color) inset;
}
/* Mobile Menu Multi Dropdown Items End */
</style>
