import axios from "axios";
import Auth from "../Auth";
const API_URL = process.env.VUE_APP_API_URL;

const HttpService = {
  async get(endpoint, params = "", withAuth = false) {
    let headers = {
      "Content-Type": "application/json",
      country:
        typeof localStorage.country === "undefined"
          ? Auth.getCountry()
          : localStorage.country,
    };
    if (withAuth && typeof localStorage.AUTH_TOKEN !== "undefined") {
      headers.Authorization = "Bearer " + localStorage.AUTH_TOKEN;
    }
    if (params != "") {
      endpoint = endpoint + params;
    }
    return await axios
      .get(`${API_URL}/${endpoint}`, { headers: headers })
      .then((response) => {
        return response;
      });
  },

  async post(endpoint, body = {}, withAuth = false) {
    let headers = {
      "Content-Type": "application/json",
      country:
        typeof localStorage.country === "undefined"
          ? Auth.getCountry()
          : localStorage.country,
    };
    if (withAuth && typeof localStorage.AUTH_TOKEN !== "undefined") {
      headers.Authorization = "Bearer " + localStorage.AUTH_TOKEN;
    }
    return await axios
      .post(`${API_URL}/${endpoint}`, body, { headers: headers })
      .then((response) => {
        return response;
      });
  },

  async delete(endpoint, withAuth = false) {
    let headers = {
      "Content-Type": "application/json",
      country:
        typeof localStorage.country === "undefined"
          ? Auth.getCountry()
          : localStorage.country,
    };
    if (withAuth && typeof localStorage.AUTH_TOKEN !== "undefined") {
      headers.Authorization = "Bearer " + localStorage.AUTH_TOKEN;
    }
    return await axios
      .delete(`${API_URL}/${endpoint}`, { headers: headers })
      .then((response) => {
        return response;
      });
  },
};

export default HttpService;
