<template>
  <div class="home-page">
    <!-- Electronics_Banner Arae -->

    <section id="electronics_banner" v-if="sliderItems.length > 0">
      <!-- :loop="true" -->
      <!-- :autoplay="true" -->
      <carousel
        class="electronics_slider_box"
        :margin="30"
        :items="sliderItems.length"
        :nav="true"
        :dots="true"
        :smartSpeed="1000"
        :responsive="{
          0: { items: 1 },
          600: { items: 1 },
          992: { items: 1 },
          1200: { items: 1 },
        }"
      >
        <div
          v-for="sliderItem in sliderItems"
          :key="sliderItem.id"
          class="electronics_slider background_bg"
          :style="'background-image: url(' + sliderItem.path + ')'"
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="electronics_slider_content">
                  <h2 v-if="sliderItem.overlay_text">
                    {{ sliderItem.overlay_text }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </carousel>
    </section>

    <!-- Electronics_Banner_Bottom Arae -->
    <section id="electronics_banner_bottom" class="ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="el_banner_bottom el-ban-bottom-left">
              <router-link to="/shop">
                <img
                  style="cursor: pointer"
                  :src="require('@/assets/img/electronics/common/offer1.jpg')"
                  alt="img"
                />
              </router-link>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="el_banner_bottom">
              <router-link to="/shop">
                <img
                  style="cursor: pointer"
                  :src="require('@/assets/img/electronics/common/offer2.jpg')"
                  alt="img"
                />
              </router-link>
            </div>
            <div class="el_banner_bottom">
              <router-link to="/shop">
                <img
                  style="cursor: pointer"
                  :src="require('@/assets/img/electronics/common/offer3.jpg')"
                  alt="img"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Top Product Area -->
    <section id="electronics_top_product" class="ptb-100" v-if="false">
      <div class="container">
        <div class="row mb-6">
          <div class="col-md-12 col-lg-12">
            <div>
              <h2 style="padding-left: 20px">Categories</h2>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div>
            <b-tabs
              vertical
              class="hot-product-area-tabs electronics-product-tabs tabs_right_button"
            >
              <b-tab
                :title="collection.name"
                v-for="(collection, idx) in categoriesWithProducts"
                :key="idx"
                class="row"
                :disabled="collection.is_parent"
                :class="collection.is_parent ? 'parent' : 'child'"
                :active="collection.active"
              >
                <div
                  class="col-lg-3 col-md-4 col-sm-6 col-12"
                  v-for="(product, index) in collection.products"
                  :key="index"
                >
                  <ProductBox4 :product="product" :index="index" />
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </section>

    <!--Sale Product Arae -->
    <section
      id="elce_weekly_deal"
      class="ptb-100 slider_arrows_on"
      v-if="saleProducts.length > 0"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="left_heading_three">
              <h2>Sale Products</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <swiper
              class="swiper todays_slider position-relative"
              :options="swiperOption"
            >
              <swiper-slide
                v-for="(product, index) in saleProducts"
                :key="index"
              >
                <ProductBox4 :product="product" :index="index" />
              </swiper-slide>

              <div
                class="swiper-button-prev swiper-button-white"
                slot="button-prev"
              ></div>
              <div
                class="swiper-button-next swiper-button-white"
                slot="button-next"
              ></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <!--Top Products Arae -->
    <section
      id="elce_weekly_deal"
      class="ptb-100 slider_arrows_on"
      v-if="featuredOrNewProducts.length > 0"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="left_heading_three">
              <h2>Top Products</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <swiper
              class="swiper todays_slider position-relative"
              :options="swiperOption"
            >
              <swiper-slide
                v-for="(product, index) in featuredOrNewProducts"
                :key="index"
              >
                <ProductBox4 :product="product" :index="index" />
              </swiper-slide>

              <div
                class="swiper-button-prev swiper-button-white"
                slot="button-prev"
              ></div>
              <div
                class="swiper-button-next swiper-button-white"
                slot="button-next"
              ></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <Alert ref="alert"></Alert>
    <!-- Add to Compare / Compare Notification  -->
  </div>
</template>

<script>
import Alert from "../components/Alert.vue";
import { mapState } from "vuex";
import ProductBox4 from "../components/product-box/ProductBox4";
// import InstagramAreaElectronics from "../components/instagram/InstagramAreaElectronics";
import carousel from "vue-owl-carousel";

export default {
  name: "Home",
  components: {
    ProductBox4,
    Alert,
    carousel,
  },

  data() {
    return {
      // Furniture Banner Slider Items
      sliderItems: [],

      products: [],
      saleProducts: [],
      featuredOrNewProducts: [],
      category: [],
      cartproduct: {},
      categoriesWithProducts: [
        {
          name: "Placemats",
          parent: "Table",
        },
        {
          name: "Cutlery Sleeves",
          parent: "Table",
        },
        {
          name: "Tissue Box",
          parent: "Table",
        },
        {
          name: "Table Runner",
          parent: "Table",
        },
        {
          name: "Tablecloth",
          parent: "Table",
        },
        {
          name: "Napkins",
          parent: "Table",
        },
        {
          name: "Coasters",
          parent: "Table",
        },
        {
          name: "Breadbasket",
          parent: "Table",
        },
        {
          name: "Guest Towels",
          parent: "Towels",
        },
        {
          name: "Beach Towel",
          parent: "Towels",
        },
        {
          name: "Hand Towels",
          parent: "Towels",
        },
        {
          name: "Hammam Towel",
          parent: "Towels",
        },
        {
          name: "Tote Bag",
          parent: "Tote Bag",
        },
        {
          name: "Beach Bag",
          parent: "Beach Bag",
        },
        {
          name: "Pouch",
          parent: "Pouch",
        },
      ],

      compareproduct: {},

      dismissCountDown: 0,

      // Todays Slider options
      swiperOption: {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 30,
        loop: false,
        mousewheel: false,
        keyboard: {
          enabled: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
        autoplay: false,
      },
    };
  },
  computed: {
    ...mapState({
      productslist: (state) => state.products.productslist,
    }),
  },
  created() {
    this.getSliderItems();
    this.getCategoriesWithProducts();
    this.getProductsWithOptions();
    // For scroll page top for every Route
    window.scrollTo(0, 0);
  },
  methods: {
    getCategoriesWithProducts() {
      this.$http.get("categories-with-products").then((res) => {
        // console.log(res);
        this.categoriesWithProducts = res.data.data;
      });
    },
    getProductsWithOptions() {
      this.$http.get("products-with-options").then((res) => {
        console.log(res);
        this.saleProducts = res.data.data.sale_products;
        this.featuredOrNewProducts = res.data.data.new_or_featured_products;
      });
    },

    // GetSlider Items
    getSliderItems: function () {
      this.$http.get("slider").then((res) => {
        this.sliderItems = res.data.data.items;
      });
    },
  },
};
</script>
