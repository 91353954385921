import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/index.vue";
import Auth from "../Auth";
// import scrollBehavior from "./router.scrollBehavior.js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { background: false },
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import("../pages/shop/index.vue"),
    // beforeEnter: (to, from, next) => {
    //   if (Auth.authenticated()) {
    //     next("/");
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: "/product/:slug",
    name: "productShow",
    component: () => import("../pages/shop/product-show.vue"),
    // beforeEnter: (to, from, next) => {
    //   if (Auth.authenticated()) {
    //     next("/");
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: "/partnerships",
    name: "partnerships",
    component: () => import("../pages/shop/index.vue"),
    beforeEnter: (to, from, next) => {
      next("/coming-soon");
    },
  },

  {
    path: "/about-us",
    name: "about-us",
    component: () => import("../pages/about-us.vue"),
    beforeEnter: (to, from, next) => {
      next("/coming-soon");
    },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("../pages/about-us.vue"),
    beforeEnter: (to, from, next) => {
      next("/coming-soon");
    },
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../pages/about-us.vue"),
    beforeEnter: (to, from, next) => {
      next("/coming-soon");
    },
  },
  {
    path: "/wishlist",
    name: "wishlist",
    component: () => import("../pages/about-us.vue"),
    beforeEnter: (to, from, next) => {
      next("/coming-soon");
    },
  },
  {
    path: "/compare",
    name: "compare",
    component: () => import("../pages/about-us.vue"),
    beforeEnter: (to, from, next) => {
      next("/coming-soon");
    },
  },

  {
    path: "/register",
    name: "register",
    component: () => import("../pages/register.vue"),
    beforeEnter: (to, from, next) => {
      if (Auth.authenticated()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/login.vue"),
    beforeEnter: (to, from, next) => {
      if (Auth.authenticated()) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../pages/profile/index.vue"),
    beforeEnter: (to, from, next) => {
      // next("/coming-soon");
      if (Auth.authenticated()) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    path: "/profile/account-details",
    name: "account-details",
    component: () => import("../pages/profile/account-details.vue"),
    beforeEnter: (to, from, next) => {
      // next("/coming-soon");
      if (Auth.authenticated()) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    path: "/profile/addresses",
    name: "account-addresses",
    component: () => import("../pages/profile/addresses.vue"),
    beforeEnter: (to, from, next) => {
      // next("/coming-soon");
      if (Auth.authenticated()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/profile/orders",
    name: "orders",
    component: () => import("../pages/profile/orders.vue"),
    beforeEnter: (to, from, next) => {
      // next("/coming-soon");
      if (Auth.authenticated()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../pages/logout.vue"),
    beforeEnter: (to, from, next) => {
      if (Auth.authenticated()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/coming-soon",
    name: "comingsoon",
    component: () => import("../pages/coming-soon.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  // scrollBehavior,
});

export default router;
