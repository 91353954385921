import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import axios from "axios";
import jQuery from "jquery";
import Auth from "./Auth";
import HttpService from "./services/HttpService";

window.jQuery = window.$ = jQuery;

// require("./assets/custom.css");

export { default as Footer } from "./components/Footer.vue";
export { default as InstagramArea } from "./components/instagram/InstagramArea.vue";
export { default as InstagramAreaElectronics } from "./components/instagram/InstagramAreaElectronics.vue";
export { default as InstagramAreaFurniture } from "./components/instagram/InstagramAreaFurniture.vue";
export { default as InstagramSliderItem } from "./components/instagram/InstagramSliderItem.vue";
export { default as ProductBox1 } from "./components/product-box/ProductBox1.vue";
export { default as ProductBox2 } from "./components/product-box/ProductBox2.vue";
export { default as ProductBox3 } from "./components/product-box/ProductBox3.vue";
export { default as ProductBox4 } from "./components/product-box/ProductBox4.vue";
export { default as ProductBox5 } from "./components/product-box/ProductBox5.vue";
export { default as BlogItem1 } from "./components/blog/BlogItem1.vue";
export { default as BlogItem2 } from "./components/blog/BlogItem2.vue";
export { default as BlogItem3 } from "./components/blog/BlogItem3.vue";
export { default as BlogSidebar } from "./components/blog/BlogSidebar.vue";
export { default as BlogSidebar2 } from "./components/blog/BlogSidebar2.vue";
export { default as WidgetsRelatedProducts } from "./components/widgets/RelatedProducts.vue";
export { default as WidgetsShopSidebar } from "./components/widgets/ShopSidebar.vue";
export { default as WidgetsTimer } from "./components/widgets/Timer.vue";

import "../node_modules/bootstrap/dist/css/bootstrap.css";

import "../node_modules/bootstrap-vue/dist/bootstrap-vue.css";

import "./assets/app.css";

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";

import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

import Vuelidate from "vuelidate";
// import VueApexCharts from "vue-apexcharts";

require("vue2-animate/dist/vue2-animate.min.css");

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

Vue.use(VueAwesomeSwiper);
Vue.component("inner-image-zoom", InnerImageZoom);
Vue.use(VueSidebarMenu);
Vue.use(Vuelidate);
// Vue.use(VueApexCharts);
// Vue.component("apexchart", VueApexCharts);
Vue.component("VueSlider", VueSlider);

import { BootstrapVue } from "bootstrap-vue";

Vue.use(BootstrapVue, {});

Vue.prototype.$http = HttpService;
// Check if in egypt or not

Vue.prototype.$country = Auth.getCountry();
Vue.prototype.$auth = Auth;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
