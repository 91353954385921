<template>
  <b-alert
    :show="dismissCountDown"
    dismissible
    fade
    :variant="variant"
    @dismissed="dismissCountDown = 0"
    @dismiss-count-down="5"
  >
    <p class="font-weight-normal">{{ msg }}</p>
  </b-alert>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {
      msg: "",
      variant: "success",
      dismissCountDown: 0,
    };
  },
  methods: {
    // alert(item) {
    //   this.dismissCountDown = item;
    // },
    success(msg) {
      this.variant = "success";
      this.msg = msg;
      this.dismissCountDown = 5;
    },
    error(msg) {
      this.msg = msg;
      this.variant = "danger";
      this.dismissCountDown = 5;
    },
  },
};
</script>
