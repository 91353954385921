<template>
  <div class="col-lg-3">
    <div class="shop_sidebar_wrapper">
      <div class="shop_Search">
        <form>
          <input
            @keyup.enter="filter"
            type="text"
            class="form-control"
            placeholder="Search..."
          />
          <button @click="filter">
            <img :src="require('@/assets/img/svg/search.svg')" alt="img" />
          </button>
        </form>
      </div>
      <div class="shop_sidebar_boxed">
        <h4>Product Categories</h4>
        <form>
          <label
            class="custom_boxed"
            v-for="(category, index) in categories"
            :key="index"
            >{{ category.parent }} - {{ category.name }}
            <input type="radio" name="radio" v-model="selectedCategory" />
            <span class="checkmark"></span>
          </label>
        </form>
      </div>

      <div class="shop_sidebar_boxed">
        <h4>Price</h4>
        <div class="price_filter" v-if="price_ranges && $country == 'eg'">
          <vue-slider
            v-model="price"
            :min="price.min"
            :max="price.max"
            ref="slider"
          >
          </vue-slider>
          <div class="price-slider-amount mt-3 text-capitalize">
            Price: {{ price.min }} EGP - {{ price.max }} EGP
          </div>
        </div>
        <div class="price_filter" v-else-if="price_ranges">
          <vue-slider
            v-model="price"
            :min="price_ranges.min_price_usd"
            :max="price_ranges.max_price_usd"
            ref="slider"
          >
          </vue-slider>
          <div class="price-slider-amount mt-3 text-capitalize">
            Price: ${{ price.min }} - ${{ price.max }}
          </div>
        </div>
      </div>

      <div class="shop_sidebar_boxed" v-if="false">
        <h4>Color</h4>
        <div class="product-variable-color">
          <label for="modal-product-color-red6">
            <input
              name="modal-product-color"
              id="modal-product-color-red6"
              class="color-select"
              type="radio"
              checked
            />
            <span class="product-color-red"></span>
          </label>
          <label for="modal-product-color-tomato1">
            <input
              name="modal-product-color"
              id="modal-product-color-tomato1"
              class="color-select"
              type="radio"
            />
            <span class="product-color-tomato"></span>
          </label>
          <label for="modal-product-color-green2">
            <input
              name="modal-product-color"
              id="modal-product-color-green2"
              class="color-select"
              type="radio"
            />
            <span class="product-color-green"></span>
          </label>
          <label for="modal-product-color-light-green3">
            <input
              name="modal-product-color"
              id="modal-product-color-light-green3"
              class="color-select"
              type="radio"
            />
            <span class="product-color-light-green"></span>
          </label>
          <label for="modal-product-color-blue4">
            <input
              name="modal-product-color"
              id="modal-product-color-blue4"
              class="color-select"
              type="radio"
            />
            <span class="product-color-blue"></span>
          </label>
          <label for="modal-product-color-light-blue5">
            <input
              name="modal-product-color"
              id="modal-product-color-light-blue5"
              class="color-select"
              type="radio"
            />
            <span class="product-color-light-blue"></span>
          </label>
        </div>
      </div>
      <div class="shop_sidebar_boxed" v-if="false">
        <h4>Size</h4>
        <form id="sizes_input">
          <label class="custom_boxed"
            >XS
            <input type="radio" checked="checked" name="radio" />
            <span class="checkmark"></span>
          </label>
          <label class="custom_boxed"
            >S
            <input type="radio" name="radio" />
            <span class="checkmark"></span>
          </label>
          <label class="custom_boxed"
            >M
            <input type="radio" name="radio" />
            <span class="checkmark"></span>
          </label>
          <label class="custom_boxed"
            >L
            <input type="radio" name="radio" />
            <span class="checkmark"></span>
          </label>
          <label class="custom_boxed"
            >XL
            <input type="radio" name="radio" />
            <span class="checkmark"></span>
          </label>
        </form>
      </div>
      <div class="shop_sidebar_boxed">
        <div class="clear_button">
          <button @click="filter" class="theme-btn-one btn-filter btn_sm mt-1">
            Filter
          </button>

          <button
            @click="clearFilter"
            class="theme-btn-one btn_sm btn-black-overlay mt-1"
          >
            Clear Filter
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ShopSidebar",

  data() {
    return {
      // For Range Slider Value
      price:
        this.$country == "eg"
          ? [this.price_ranges.min_price_egp, this.price_ranges.max_price_egp]
          : [this.price_ranges.min_price_usd, this.price_ranges.max_price_usd],
      categories: [],
      selectedCategory: "",
    };
  },
  props: ["price_ranges"],
  methods: {
    getCategories() {
      this.$http.get("categories").then((res) => {
        this.categories = res.data.data;
      });
    },
    filter() {
      console.log($refs.slider.getValue());
    },
    clearFilter() {},
  },
  mounted() {
    this.getCategories();
  },
};
</script>
