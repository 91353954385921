import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
export default {
  authenticated() {
    return typeof localStorage.AUTH_TOKEN !== "undefined";
  },
  getCountry() {
    let country = "eg";
    if (typeof localStorage.country === "undefined") {
      axios
        .get("https://api.ipregistry.co?key=ry7frih9e9mka8eu")
        .then((res) => {
          if (typeof res.data.location.country.code !== "undefined") {
            country = res.data.location.country.code.toLowerCase();
            localStorage.setItem("country", country);
          } else {
            localStorage.setItem("country", country);
          }
        });
    }
    return country;
  },
  saveAuthInfo(info) {
    if (info.id) {
      localStorage.setItem("AUTH_ID", info.id);
    }
    if (info.user_first_name) {
      localStorage.setItem("AUTH_FIRST_NAME", info.user_first_name);
    }
    if (info.user_last_name) {
      localStorage.setItem("AUTH_LAST_NAME", info.user_last_name);
    }
    if (info.user_mobile) {
      localStorage.setItem("AUTH_MOBILE", info.user_mobile);
    }
    if (info.user_email) {
      localStorage.setItem("AUTH_EMAIL", info.user_email);
    }
    if (info.access_token) {
      localStorage.setItem("AUTH_TOKEN", info.access_token);
    }
  },
  verifyToken() {
    if (typeof localStorage.AUTH_TOKEN !== "undefined") {
      axios
        .post(
          `${API_URL}/token/verify`,
          {},
          {
            headers: { Authorization: "Bearer " + localStorage.AUTH_TOKEN },
          },
        )
        .then((response) => {
          if (!response.data.data.auth) {
            localStorage.removeItem("AUTH_TOKEN");
            location.reload();
          }
        });
    }
  },
  logout() {
    localStorage.removeItem("AUTH_ID");
    localStorage.removeItem("AUTH_FIRST_NAME");
    localStorage.removeItem("AUTH_LAST_NAME");
    localStorage.removeItem("AUTH_MOBILE");
    localStorage.removeItem("AUTH_EMAIL");
    localStorage.removeItem("AUTH_TOKEN");
  },
};
