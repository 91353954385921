<template>
  <div>
    <!-- footer Area -->
    <footer id="footer_one">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12 col-12">
            <div class="footer_left_side">
              <router-link to="/"
                ><img :src="require('@/assets/ticas-logo.png')" alt="logo"
              /></router-link>
              <!-- <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Obcaecati culpa assumenda voluptas placeat amet consectetur
                adipisicing elit. Obcaecati culpa assumenda voluptas placeat.
              </p> -->
              <div class="footer_left_side_icon text-center">
                <ul>
                  <li>
                    <a href="#!"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="#!"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#!"><i class="fab fa-instagram"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="footer_one_widget">
              <h3>Pages</h3>
              <ul>
                <li><router-link to="/">Home</router-link></li>
                <li>
                  <router-link to="/shop">Shop</router-link>
                </li>
                <li>
                  <router-link to="/partnerships"
                    >Partnerships & Collaborations</router-link
                  >
                </li>
                <li>
                  <router-link to="/about-us">About Us</router-link>
                </li>
                <li>
                  <router-link to="/contact-us">Contact Us</router-link>
                </li>
                <!-- <li>
                  <router-link to="/compare">Compare</router-link>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-6 col-sm-12 col-12">
            <div class="footer_one_widget">
              <h3>Your Account</h3>
              <ul v-if="authenticated">
                <li><router-link to="/profile">Dashboard</router-link></li>
                <li><router-link to="/profile/orders">Orders</router-link></li>
                <li>
                  <router-link to="/profile/addresses">Addresses</router-link>
                </li>
                <li>
                  <router-link to="/cart">Cart</router-link>
                </li>
                <li>
                  <router-link to="/wishlist">Wishlist</router-link>
                </li>
              </ul>
              <ul v-else>
                <li>
                  <router-link to="/login">Login | Register</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="footer_one_widget">
              <h3>NEWS LETTER</h3>
              <div id="mc_embed_signup" class="subscribe-form">
                <form @submit.prevent="handleSubmit">
                  <div class="mc-form">
                    <input
                      class="form-control"
                      type="email"
                      v-model="user.email"
                      name="email"
                      placeholder="Your Mail*"
                      :class="{
                        'is-invalid': submitted && $v.user.email.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.user.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.email.required"
                        >Email is required</span
                      >
                      <span v-if="!$v.user.email.email">Email is invalid</span>
                    </div>
                    <div class="clear">
                      <button class="theme-btn-one btn_md" name="subscribe">
                        <i class="icon-cursor"></i> Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Alert ref="alert"></Alert>
      </div>
    </footer>

    <!-- CopyRight Area -->
    <section id="copyright_one">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="copyright_left">
              <h6>© CopyRight {{ year }} <span>Tica's</span></h6>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="copyright_right">
              Two Mushrooms Trading L.L.C || commercial register 1929577
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Alert from "../components/Alert.vue";
import { required, email } from "vuelidate/lib/validators";
import Auth from "../Auth";
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      // Form Validation
      user: {
        email: "",
      },
      submitted: false,
      authenticated: Auth.authenticated(),
    };
  },

  validations: {
    user: {
      email: { required, email },
    },
  },
  components: {
    Alert,
  },
  methods: {
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$refs.alert.success("Subscribed to newsletter!");
    },
  },
};
</script>
